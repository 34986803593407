import { cx } from "@linaria/core";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { BaseHTMLAttributes, ChangeEvent, FC, memo, useCallback, useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import { LazyHydrateContainer } from "@/hoc/LazyHydrate";
import { useSearch } from "@/hooks/search/search";
import { cssIsActive } from "@/styles/utils/Utils";
import { Button } from "@/ui/Button/Button";
import { Field } from "@/ui/Field/Field";
import { cssBackIsActive, cssSearchSubmit, StyledField, StyledFieldForm } from "./StyledField";
import { AutoComplete } from "../AutoComplete";
import { StyledAutoCompleteOverlay } from "../AutoComplete/StyledAutoComplete";
const SearchField: FC<BaseHTMLAttributes<HTMLDivElement> & {
  isResponsiveMode?: boolean;
  isFocusInit?: boolean;
  isBackButton?: boolean;
  onBackCb?: () => void;
}> = memo(({
  isResponsiveMode = false,
  isFocusInit = false,
  isBackButton = true,
  onBackCb,
  className,
  ...props
}) => {
  const {
    updateQueryText,
    appendToHistory,
    correction,
    query,
    updateSearch,
    setIsSubmitting,
    inputRef,
    autoComplete: {
      ref,
      hide,
      show,
      isShow
    },
    clearAutoComplete,
    inputFocus,
    currentQuery
  } = useSearch();
  const router = useRouter();
  const {
    handleSubmit,
    control,
    setValue,
    formState: {
      submitCount,
      isSubmitting
    }
  } = useForm<{
    query: string;
  }>({
    defaultValues: {
      query: currentQuery || ""
    }
  });
  const onSubmit = handleSubmit(data => {
    hide();
    inputRef?.current?.blur();
    if (data.query.length > 0) {
      appendToHistory({
        query: data.query
      });
    }
    updateSearch({
      query: data.query,
      withReset: true
    });
  });
  useEffect(() => {
    if (isFocusInit) {
      inputFocus();
    }
  }, [inputFocus, isFocusInit]);
  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting, setIsSubmitting]);
  useEffect(() => {
    setValue("query", currentQuery || "", {
      shouldDirty: true,
      shouldTouch: true
    });
  }, [currentQuery, setValue]);
  useEffect(() => {
    if (correction !== null && correction !== currentQuery && query !== null && query === currentQuery) {
      setValue("query", correction || "", {
        shouldDirty: false,
        shouldTouch: false
      });
    }
  }, [correction, setValue, currentQuery, submitCount, query]);
  const mobileButtonElement = useMemo(() => {
    if (!isResponsiveMode) {
      return null;
    }
    if (isBackButton) {
      return <Button variant={"box"} icon={isShow ? "X" : "ArrowLeft"} type={"button"} className={cx(isShow && cssBackIsActive)} onClick={() => {
        if (onBackCb !== undefined) {
          onBackCb();
        } else {
          if (isShow) {
            hide();
          } else {
            void router.push("/", undefined, {
              shallow: false
            });
          }
        }
      }} />;
    } else {
      return isShow && <Button variant={"box"} icon={"X"} type={"button"} className={cssBackIsActive} seoText={"Поиск"} onClick={() => {
        if (onBackCb !== undefined) {
          onBackCb();
        } else {
          hide();
        }
      }} />;
    }
  }, [isResponsiveMode, isBackButton, isShow, hide]);
  const onChangeFieldHandle = useCallback((e: ChangeEvent<HTMLInputElement>) => (field: ControllerRenderProps<{
    query: string;
  }, "query">) => {
    field.onChange(e);
    updateQueryText(e.target.value);
  }, [updateQueryText]);
  return <>
        <StyledField ref={ref} className={className} {...props}>
          <StyledFieldForm onSubmit={onSubmit}>
            {mobileButtonElement}

            <Controller control={control} name={"query"} render={({
          field
        }) => {
          return <Field {...field} name={"query"} id={"id-search"} placeholder={"Например, стакан ..."} aria-label={"Поиск"} type={"text"} variant={"input"} withAnimatingLabel={false} withButton iconButton={isResponsiveMode ? undefined : "Search"} className={"searchField"} classNameButton={cssSearchSubmit} textButton={isResponsiveMode ? "Найти" : undefined} variantButton={isResponsiveMode ? "filled" : undefined} typeButton={"submit"} withClean cleanCb={clearAutoComplete} onChange={e => {
            onChangeFieldHandle(e)(field);
          }} onFocus={e => {
            e.preventDefault();
            show();
          }} ref={inputRef} seoTextButton={"Поиск"} />;
        }} />
          </StyledFieldForm>

          <LazyHydrateContainer>
            <AutoComplete fieldRef={ref} selectQuery={(query: string) => {
          setValue("query", query, {
            shouldDirty: true,
            shouldTouch: true
          });
          void onSubmit();
        }} showAllResults={() => {
          void onSubmit();
        }} />
          </LazyHydrateContainer>
        </StyledField>
        <StyledAutoCompleteOverlay className={cx(isShow && cssIsActive)} onClick={hide} />
      </>;
});
SearchField.displayName = "SearchField";
export { SearchField };